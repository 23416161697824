import React, {useRef, useState} from 'react';
import {toast} from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';
import {Link} from 'react-router-dom';
import emailjs from '@emailjs/browser';

const FormThree = () => {

    const formRef = useRef(null);

    const [recaptcha, setRecaptcha] = useState(null);

    const [recaptchaError, setRecaptchaError] = useState(false);

    const [messageSending, setMessageSending] = useState(false);

    const [isVerified, setIsVerified] = useState(false);

    const handleRecaptchaChange = (value) => {
        setIsVerified(!!value);
    };

    const sendEmail = (event) => {
        event.preventDefault();

        if (!isVerified) {

            // if (!window.UC_UI) {
            //   return;
            // }
            //
            // if (window.UC_UI.areAllConsentsAccepted()) {
            //   setRecaptchaError(false);
            //   setRecaptcha(<ReCAPTCHA sitekey="6LfGFisnAAAAAO6kIa0AdLuhbySWkeuAqanaqWDd"
            //                           onChange={handleRecaptchaChange}/>);
            // } else {
            //   setRecaptchaError(true);
            // }
            return;
        }

        const [fullName, emailAddress, phoneNumber, message] = event.target;

        emailjs.init({
            publicKey: 'IIpBnA9rGypp-S-RK'
        });


        const body = {
            devoteOrigin: 'Devote Austria',
            fullName: fullName.value,
            emailAddress: emailAddress.value,
            phoneNumber: phoneNumber.value,
            message: message.value
        };

        setMessageSending(true);

        emailjs.send('service_9rodz7d', 'template_id6wlvf', body, 'IIpBnA9rGypp-S-RK').then(
          function (response) {
              toast.success('Nachricht gesendet. Wir melden uns bald bei Ihnen.', {
                  position: 'bottom-center',
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                  progress: undefined,
                  theme: 'dark'
              });
              setMessageSending(false);
              formRef.current.reset();
          },
          function (err) {
              toast.error('Beim Senden der Nachricht ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.', {
                  position: 'bottom-center',
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                  progress: undefined,
                  theme: 'dark'
              });
              setMessageSending(false);
          }
        ).catch((error) => {
            toast.error('Beim Senden der Nachricht ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: 'dark'
            });
            setMessageSending(false);
        });
    };

    return (
      <form ref={formRef} onSubmit={sendEmail} method="POST" className="axil-contact-form">
          <div className="form-group">
              <label>Name</label>
              <input type="text" className="form-control" name="contact-name" placeholder="Vor- und Nachname"
                     required/>
          </div>
          <div className="form-group">
              <label>E-Mail</label>
              <input type="email" className="form-control" name="contact-email" placeholder="du@deinefirma.com"
                     required/>
          </div>
          <div className="form-group">
              <label>Telefon</label>
              <input type="phone" className="form-control" name="contact-phone" placeholder="Telefonnummer" required/>
          </div>
          <div className="form-group mb--40">
              <label>Nachricht</label>
              <textarea name="contact-message" id="contact-message" className="form-control textarea" cols="30"
                        rows="6"></textarea>
          </div>
          <div className="form-group mb--40">
              <ReCAPTCHA sitekey="6LfGFisnAAAAAO6kIa0AdLuhbySWkeuAqanaqWDd"
                         onChange={handleRecaptchaChange}/>
          </div>
          {
            recaptchaError &&
            <p>Aktivieren Sie bitte die Cookies von unserem <Link to={''} onClick={() => window.UC_UI.showSecondLayer()}>Cookie Consent Tool</Link>, um uns eine Nachricht zu senden.</p>
          }
          <div className="form-group text-end">

              <button type="submit"
                      className={`axil-btn btn-fill-primary w-auto btn-primary ${!recaptcha || messageSending ? 'disabled' : ''}`}
                      name="submit-btn">Nachricht senden
              </button>
          </div>
      </form>
    );
};

export default FormThree;
