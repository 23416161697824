import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
  {
    id: 1,
    title: 'Kollaborativer Ansatz',
    para: 'Wir schätzen Zusammenarbeit und glauben, dass die besten Ergebnisse aus der engen Zusammenarbeit mit unseren Kunden kommen. Wir hören aufmerksam auf Ihre Ideen, Ziele und Herausforderungen und integrieren Ihre wertvollen Inputs während des gesamten Entwicklungsprozesses. Ihre Vision steht im Mittelpunkt unserer Arbeit.'
  },
  {
    id: 2,
    title: 'Technische Expertise',
    para: 'Mit jahrelanger Erfahrung in der Branche besitzt unser Team die technische Expertise, um Ihre Ideen in robuste, skalierbare Lösungen zu verwandeln. Von Webentwicklung und E-Commerce-Lösungen bis hin zu Design und Branding haben wir die Fähigkeiten und das Wissen, um vielfältige Projekte in verschiedenen Branchen zu bewältigen.'
  },
  {
    id: 3,
    title: 'Innovation und Kreativität',
    para: 'Wir setzen auf Innovation und fördern Kreativität in allen Bereichen unserer Arbeit. Wir bringen frische Ideen ein und finden erfinderische Lösungen für komplexe Herausforderungen. Unser Ziel ist es, einzigartige und wirkungsvolle Lösungen zu liefern, die Sie von Ihren Mitbewerbern abheben und einen bleibenden Eindruck bei Ihrem Publikum hinterlassen.'
  },
  {
    id: 4,
    title: 'Aufmerksamkeit für Details',
    para: 'Wir achten bei jedem Projekt, das wir übernehmen, penibel auf Details. Vom ersten Planungsschritt bis zur abschließenden Umsetzung stellen wir sicher, dass jedes Element sorgfältig ausgearbeitet und akribisch umgesetzt wird. Unsere Verpflichtung zur Exzellenz garantiert, dass Ihr Projekt höchsten Qualitätsstandards entspricht.'
  }
];

const AboutThree = () => {

  return (
    <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
      <div className="container">
        <SectionTitle
          subtitle={'Unsere Werte'}
          title={'Warum sollten Sie mit uns arbeiten?'}
          description={'Ihre Vision, Unsere Expertise: Zusammenarbeit für unübertroffene Lösungen'}
          textAlignment="heading-left heading-light-left mb--100"
          textColor=""
        />

        <div className="row">
          {Datas.map((data) => (
            <div className="col-lg-4" key={data.id}>
              <div className="about-quality">
                <h3 className="sl-number">{data.id}</h3>
                <h5 className="title">{data.title}</h5>
                <p>{data.para}</p>
              </div>
            </div>
          ))}

        </div>
      </div>
      <ul className="list-unstyled shape-group-10">
        <li className="shape shape-1"><img src={process.env.PUBLIC_URL + '/images/others/circle-1.png'} alt="Kreis"/>
        </li>
        <li className="shape shape-2"><img src={process.env.PUBLIC_URL + '/images/others/line-3.png'} alt="Kreis"/>
        </li>
        <li className="shape shape-3"><img src={process.env.PUBLIC_URL + '/images/others/bubble-5.png'} alt="Kreis"/>
        </li>
      </ul>
    </div>
  );
};

export default AboutThree;
