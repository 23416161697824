import React from 'react';
import SEO from '../common/SEO';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ContactLocation from '../component/contact/ContactLocation';
import FormThree from "../component/contact/FormThree";

const Contact = () => {

    return (
        <>
            <SEO title="Kontaktiere uns" />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne
                    title="Kontaktiere uns"
                    page="Kontaktiere uns"
                />

            <div className="section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-6">
                            <div className="contact-form-box shadow-box mb--30">
                                <h3 className="title">Kontakt aufnehmen</h3>
                                <FormThree />
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 offset-xl-1">
                            <div className="contact-info mb--100 mb_md--30 mt_md--0 mt--150">
                                <h4 className="title">Telefon</h4>
                                {/*<p>Unser Kundenservice ist von Mo-Fr, 10:00 Uhr bis 18:00 Uhr geöffnet</p>*/}
                                <h4 className="phone-number"><a href="tel:+43 664 4538891">+43 664 4538891</a></h4>
                            </div>
                            <div className="contact-info mb--30">
                                <h4 className="title">E-Mail</h4>
                                {/*<p>Unser Support-Team wird Ihnen innerhalb von 48 Stunden während der üblichen Geschäftszeiten antworten.</p>*/}
                                <h4 className="phone-number"><a href="mailto:hello@devote.at">hello@devote.at</a></h4>
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="list-unstyled shape-group-12">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="Blase" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="Blase" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/circle-3.png"} alt="Kreis" /></li>
                </ul>
            </div>

            <div className="section section-padding bg-color-dark overflow-hidden">
                <div className="container">
                    <SectionTitle
                        subtitle="Finde uns"
                        title="Unser Büro"
                        description=""
                        textAlignment="heading-light-left"
                        textColor=""
                    />
                    <div className="row">
                        <ContactLocation />
                    </div>
                </div>
                <ul className="shape-group-11 list-unstyled">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/line-6.png"} alt="Linie" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/circle-3.png"} alt="Linie" /></li>
                </ul>
            </div>

            <FooterOne parentClass="pt--150 pt_lg--100 pt_md--80 pt_sm--60" />
            </main>
        </>
    )
}

export default Contact;
