import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ScrollToTop from './component/scrollToTop/ScrollToTop';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomeStartup from './pages/HomeStartup';
import ServiceDetails from './pages/ServiceDetails';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import ErrorPage from './pages/404';
import PrivacyPolicy from './pages/PrivacyPolicy';
import './assets/scss/app.scss';
import Impressum from './pages/Impressum';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga4';

const App = () => {

  const TRACKING_ID = 'G-NYQK87RGW7';

  ReactGA.initialize(TRACKING_ID);

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path={process.env.PUBLIC_URL + '/'} element={<HomeStartup/>}/>

            {/* Service */}
            <Route path={process.env.PUBLIC_URL + '/:slug'} element={<ServiceDetails/>}/>

            {/* Pages  */}
            <Route path={process.env.PUBLIC_URL + '/about-us'} element={<AboutUs/>}/>
            <Route path={process.env.PUBLIC_URL + '/contact-us'} element={<Contact/>}/>
            <Route path={process.env.PUBLIC_URL + '/404'} element={<ErrorPage/>}/>
            <Route path={process.env.PUBLIC_URL + '/impressum'} element={<Impressum/>}/>
            <Route path={process.env.PUBLIC_URL + '/privacy-policy'} element={<PrivacyPolicy/>}/>

          </Routes>
        </ScrollToTop>
      </Router>
      <ToastContainer/>
    </>
  );
};

export default App;
