import React, {useEffect} from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import SEO from '../common/SEO';


const TermsOfUse = () => {

  window.UC_UI_SUPPRESS_CMP_DISPLAY = true;

  return (
    <>
      <SEO title="Impressum"/>
      <main className="main-wrapper">
        <HeaderOne/>
        <BreadCrumbOne
          title="Impressum"
          page="Impressum"
        />
        <div className="section-padding privacy-policy-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="privacy-policy-content">
                  {/*<h4>Angaben gem&auml;&szlig; &sect; 5 TMG</h4>*/}
                  <h4>Name</h4>
                  <p>Emin Serezlija</p>

                  <h4>Anschrift</h4>
                  <p>Richard-Wagner-Gasse 33, 8010 Graz, Austria</p>

                  <h4>Unternehmensgegenstand</h4>
                  <p>IT Development</p>

                  <h4>Kontaktdaten</h4>
                  <p>
                    Tel: +43 664 453 8891<br/>
                    E-Mail: info@devote.at
                  </p>

                  <h4>Mitgliedschaften bei der Wirtschaftskammerorganisation</h4>
                  <p>Mitglied der WKÖ</p>

                  <h4>Berufsrecht</h4>
                  <p>Gewerbeordnung: <a href="https://www.ris.bka.gv.at">www.ris.bka.gv.at</a></p>

                  <h4>Gewerbebehörde</h4>
                  <p>Bezirkshauptmannschaft Graz</p>

                  <h4>Berufsbezeichnung</h4>
                  <p>
                    Dienstleistungen in der automatischen<br/>
                    Datenverarbeitung und Informationstechnik
                  </p>

                  <h4>Angaben zur Online-Streitbeilegung</h4>
                  <p>
                    Verbraucher haben die Möglichkeit, Beschwerden an die
                    Online-Streitbeilegungsplattform der EU zu richten: <a href="https://ec.europa.eu/odr">https://ec.europa.eu/odr</a>. Sie können allfällige Beschwerde
                    auch an die oben angegebene E-Mail-Adresse richten.
                  </p>

                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterOne parentClass=""/>
      </main>
    </>
  );
};

export default TermsOfUse;
