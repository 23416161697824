import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BannerThree from '../component/banner/BannerThree';
import Cta from '../component/cta/Cta';
import ServicePropOne from '../component/service/ServicePropOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import WhyChooseOne from '../component/whychoose/WhyChooseOne';
import AboutThree from '../component/about/AboutThree';

const HomeStartup = () => {

  return (
    <>
      <SEO title={'Home'}/>
      <main className="main-wrapper">
        <HeaderOne/>
        <BannerThree/>
        <div className="section section-padding-2 bg-color-dark">
          <div className="container">
            <SectionTitle
              subtitle={'Was wir für Sie tun können'}
              title={'Dienstleistungen, bei denen wir Ihnen helfen können'}
              description={'Visionen in Erfolgsgeschichten verwandeln: Entdecken Sie unser Serviceangebot'}
              textAlignment="heading-light-left"
              textColor=""
            />
            <div className="row">
              <ServicePropOne colSize="col-xl-4 col-md-6" serviceStyle="" itemShow="3"/>
            </div>
          </div>
          <ul className="list-unstyled shape-group-10">
            <li className="shape shape-1"><img src={process.env.PUBLIC_URL + '/images/others/line-9.png'}
                                               alt="Kreis"/></li>
            <li className="shape shape-2"><img src={process.env.PUBLIC_URL + '/images/others/bubble-42.png'}
                                               alt="Kreis"/></li>
            <li className="shape shape-3"><img src={process.env.PUBLIC_URL + '/images/others/bubble-43.png'}
                                               alt="Kreis"/></li>
          </ul>
        </div>
        <WhyChooseOne/>
        <AboutThree/>
        <Cta/>
        <FooterOne/>
      </main>
    </>
  );
};

export default HomeStartup;
