import React from 'react';
import {useParams} from 'react-router-dom';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SectionTitle from '../elements/section-title/SectionTitle';
import ServiceData from "../data/service/ServiceMain.json";
import SEO from '../common/SEO';
import Tilty from 'react-tilty';
import Cta from "../component/cta/Cta";

const allServiceData = ServiceData;

const ServiceDetails = () => {

    const params = useParams();
    const serviceSlug = params.slug;

    const getServiceData = allServiceData.filter(data => data.slug === serviceSlug);
    const detailsService = getServiceData[0];

    return (
        <>
        <SEO title={detailsService.title} />
        <main className="main-wrapper">
            <HeaderOne />
            <div className="section section-padding pt--150 bg-color-light pb--70">
                <SectionTitle
                    subtitle={detailsService.process.sectionSub}
                    title={detailsService.process.sectionTitle}
                    description={detailsService.process.para}
                    textAlignment=""
                    textColor="mb--90"
                />
                <div className="container">
                    {detailsService.process.steps.map((data, index) => (
                        <div key={index} className={`process-work ${(index % 2  === 1) ? "content-reverse" : ""}`}>
                            <Tilty perspective={2000}>
                                <div className="thumbnail">
                                    <img src={process.env.PUBLIC_URL + data.thumb} alt="Thumbnail" />
                                </div>
                            </Tilty>
                        <div className="content">
                            <span className="subtitle">{data.subtitle}</span>
                            <h3 className="title">{data.title}</h3>
                            <p>{data.paragraph}</p>
                        </div>
                    </div>
                    ))}
                </div>
                <ul className="shape-group-17 list-unstyled">
                    <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-24.png"} alt="Bubble" /></li>
                    <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/bubble-23.png"} alt="Bubble" /></li>
                    <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Line" /></li>
                    <li className="shape shape-4"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="Line" /></li>
                    <li className="shape shape-5"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Line" /></li>
                    <li className="shape shape-6"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="Line" /></li>
                </ul>
            </div>
            <Cta />
        <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default ServiceDetails;
