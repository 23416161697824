import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import SectionTitle from '../../elements/section-title/SectionTitle';

const WhyChooseOne = () => {

  return (
    <div className="section section-padding bg-color-light">
      <div className="container">
        <div className="why-choose-us pt-4">
          <SectionTitle
            subtitle={'Lösungen entwickeln, #ErfolgFördern'}
            title={'Ihre Ideen verwirklichen'}
            description={'Warum Uns Wählen'}
            textAlignment="heading"
            textColor=""
          />
        </div>
      </div>
      <ul className="shape-group-7 list-unstyled">
        <li className="shape shape-1"><img src={process.env.PUBLIC_URL + '/images/others/circle-2.png'}
                                           alt="Kreis"/></li>
        <li className="shape shape-2"><img src={process.env.PUBLIC_URL + '/images/others/bubble-2.png'}
                                           alt="Linie"/></li>
        <li className="shape shape-3"><img src={process.env.PUBLIC_URL + '/images/others/bubble-1.png'}
                                           alt="Linie"/></li>
      </ul>
    </div>

  );
};

export default WhyChooseOne;
