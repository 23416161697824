import React from 'react';
import {Link} from 'react-router-dom';
import ServiceData from "../../data/service/ServiceMain.json";

const getServiceData = ServiceData;

const FooterOne = ({parentClass}) => {

    return (
        <footer className={`footer-area ${parentClass}`}>
            <div className="container">
                <div className="footer-top">
                </div>
                <div className="footer-main">
                    <div className="row">
                        <div className="col-xl-5 col-lg-4">
                            <div className="footer-widget border-end">
                                <div className="header-logo">
                                    <img className="light-version-logo"
                                         src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="Logo"/>
                                </div>
                                <div className="text-gray-400 mt-2">
                                    Lösungen entwickeln, #ErfolgAntreiben
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-8">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Dienstleistungen</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                {getServiceData.slice(0, 6).map((data, index) => (
                                                    <li key={index}>
                                                        <Link
                                                            to={process.env.PUBLIC_URL + `/${data.slug}`}>{data.title}</Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-5">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Kontakt</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><Link to={'#'}>hello@devote.at</Link></li>
                                                <li><Link to={'#'}>+43 664 453 8891</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Unternehmen</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><Link to={process.env.PUBLIC_URL + "/impressum"}>Impressum</Link>
                                                </li>
                                                <li><Link
                                                    to={process.env.PUBLIC_URL + "/privacy-policy"}>Datenschutzrichtlinie</Link>
                                                </li>
                                                {/*<li><Link to={'#'}*/}
                                                {/*       onClick={() => window.UC_UI.showSecondLayer()}*/}
                                                {/*>Cookie-Einstellungen</Link>*/}
                                                {/*</li>*/}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="footer-copyright">
                                <span className="copyright-text">© Devote {new Date().getFullYear()}. Alle Rechte vorbehalten.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterOne;
