import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import AboutThree from '../component/about/AboutThree';
import Cta from "../component/cta/Cta";
import WhyChooseOne from "../component/whychoose/WhyChooseOne";


const AboutUs = () => {

    return (
        <>
            <SEO title="About Us"/>
            <main className="main-wrapper">
                <HeaderOne/>
                <WhyChooseOne/>
                <AboutThree/>
                <Cta/>
                <FooterOne parentClass=""/>
            </main>
        </>
    )
}

export default AboutUs;
